import React from 'react';

import Layout from '../components/Layout';
import PageFooter from '../components/PageFooter';
import SideBar from '../components/SideBar';

import flosOlei from '../assets/images/flos-olei2020.png';
import eastwest from '../assets/images/eastwest.png';
import babibooks from '../assets/images/babibooks.jpg';
import appStore from '../assets/images/app-store-badge.svg';
import playStore from '../assets/images/play-store-badge.png';
// import pic5 from '../assets/images/pic05.jpg';
// import pic6 from '../assets/images/pic06.jpg';
// import pic7 from '../assets/images/pic07.jpg';
import aboutMe from '../assets/images/about-me.jpg';
const sections = [
  { id: 'top', name: 'Intro', icon: 'fa-home' },
  { id: 'portfolio', name: 'Portfolio', icon: 'fa-th' },
  { id: 'about', name: 'About Me', icon: 'fa-user' },
  { id: 'contact', name: 'Contact', icon: 'fa-envelope' },
];

const titleStyle = {
  fontSize: '2em',
  fontWeight: 'bold',
};

const mottoStyle = {
  backgroundColor: 'rgba(240, 240, 240, 0.2)',
  borderRadius: '10pt',
  color: '#95A4B9',
};

const appBadgeStyle = {
  width: '150px',
};

const playBadgeStyle = {
  width: '174px',
};

const IndexPage = () => (
  <Layout>
    <SideBar sections={sections} />

    <div id="main">
      <section id="top" className="one dark cover">
        <div className="container">
          <header>
            <h2 className="alt" style={titleStyle}>
              <strong>Babisoft</strong>
              <br />
            </h2>
            <p style={mottoStyle}>
              Useful software for small and big problems...
            </p>
          </header>

          <footer>
            {/* <Scroll type="id" element={'portfolio'}>
              <a href="#portfolio" className="button">
                Show me
              </a>
            </Scroll> */}
          </footer>
        </div>
      </section>

      <section id="portfolio" className="two">
        <div className="container">
          <header>
            <h2>Portfolio</h2>
          </header>

          <p>
            Hi, I develop software since 1998, here you can find some old and
            new projects...
          </p>

          <div className="row">
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a href="/#" className="image fit">
                  <img src={babibooks} alt="" />
                </a>
                <header>
                  <h3>BabiBooks</h3>
                  <br />
                  <a
                    href="https://apps.apple.com/it/app/babibooks/id326692816"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appStore}
                      alt="Download on the App Store"
                      style={appBadgeStyle}
                    />
                    <div>
                      <div>
                        <a href="http://www.babisoft.com/downloads/BabiBooksSynch/BabiBooksSynchWin1.1.0.zip">
                          BabiBooks Sync for Windows
                        </a>
                      </div>
                      <div>
                        <a href="http://www.babisoft.com/downloads/BabiBooksSynch/BabiBooksSynchMac20.zip">
                          BabiBooks Sync for Mac
                        </a>
                      </div>
                    </div>
                  </a>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a href="/#" className="image fit">
                  <img src={flosOlei} alt="Flos Olei 2020" />
                </a>
                <header>
                  <h3>Flos Olei 2020</h3>
                  <br />
                  <a
                    href="https://apps.apple.com/us/app/flos-olei-2020-world/id1488953663?ign-mpt=uo%3D2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appStore}
                      alt="Download on the App Store"
                      style={appBadgeStyle}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.flosolei.world2020"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={playStore}
                      alt="Get it on the Play Store"
                      style={playBadgeStyle}
                    />
                  </a>
                </header>
              </article>
            </div>
            <div className="col-4 col-12-mobile">
              <article className="item">
                <a href="/#" className="image fit">
                  <img src={eastwest} alt="" />
                </a>
                <header>
                  <h3>Eastwest</h3>
                  <br />
                  <a
                    href="https://apps.apple.com/it/app/eastwest/id660026596"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={appStore}
                      alt="Download on the App Store"
                      style={appBadgeStyle}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.europeye.east"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={playStore}
                      alt="Get it on the Play Store"
                      style={playBadgeStyle}
                    />
                  </a>
                </header>
              </article>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: '10px  ' }}>
          <div className="col-12 col-12-mobile">
            You can find older software (CDEdit, TapEdit, RSSFeedEater...) on
            the previous version of this{' '}
            <a
              href="http://www.babisoft.com/previous-site/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              site
            </a>
            .
          </div>
        </div>
      </section>

      <section id="about" className="three">
        <div className="container">
          <header>
            <h2>About Me</h2>
          </header>

          <a href="/#" className="image featured">
            <img src={aboutMe} alt="" />
          </a>

          <p>
            Here you will find some programs I've developed during the past
            years and that I'll hopefully continue to develop in years to come.
            When I started to develop software and I began to distribute my
            programs online I was a university student so I had much time for my
            personal projects, but now I'm working full time as a software
            developer, and I'm growing with my family, so my free time has
            drastically reduced. Anyway I continue to write new software and I
            try to improve my old ones, because this is what I really like to do
            and what actually brought me into this business.
          </p>
        </div>
      </section>

      <section id="contact" className="four">
        <div className="container">
          <header>
            <h2>Contact</h2>
          </header>

          <p>Feel free to contact me, even to just say hello...</p>

          <form
            method="post"
            action="https://getform.io/f/17a435f7-f59e-4ebf-9f57-87fd9ef6d658"
          >
            <div className="row">
              <div className="col-6 col-12-mobile">
                <input type="text" name="name" placeholder="Name" />
              </div>
              <div className="col-6 col-12-mobile">
                <input type="text" name="email" placeholder="Email" />
              </div>
              <div className="col-12">
                <textarea name="message" placeholder="Message" />
              </div>
              <div className="col-12">
                <input type="submit" value="Send Message" />
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>

    <PageFooter />
  </Layout>
);

export default IndexPage;
